import React, { useState, useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import { FaRegCopy } from "react-icons/fa6";

import classes from "./DataTable.module.css";
import {
  convertTimestampToFormattedDate,
  shortenEthereumAddress,
  formatNumberWithCommas,
} from "../../common/utils";
import { formatEther } from "ethers";

const SingleRow = ({
  amount,
  apr,
  blockNumber,
  blockTimestamp,
  id,
  lastWithdrawnTime,
  ref1,
  ref2,
  ref3,
  ref5,
  ref4,
  stakeID,
  transactionHash,
  unlockTime,
  user,
  withdrawnAmount,
}) => {
  return (
    <tr>
      <td>#{stakeID}</td>
      <td>
        <div className={classes.amount}>
          {Number(formatEther(amount)).toFixed(2)}
        </div>
      </td>
      <td>
        <div className={classes.shortend}>
          {shortenEthereumAddress(id)}
          <CopyToClipboard text={id}>
            <FaRegCopy className={classes.copyIcon} />
          </CopyToClipboard>
        </div>
      </td>
      <td>
        <div className={classes.shortend}>
          {shortenEthereumAddress(user)}
          <CopyToClipboard text={user}>
            <FaRegCopy className={classes.copyIcon} />
          </CopyToClipboard>
        </div>
      </td>
      <td>
        <div className={classes.shortend}>
          {shortenEthereumAddress(transactionHash)}
          <CopyToClipboard text={transactionHash}>
            <FaRegCopy className={classes.copyIcon} />
          </CopyToClipboard>
        </div>
      </td>
      <td>
        <span className={classes.blockedNumber}>{blockNumber}</span>
      </td>
      <td>{apr}</td>
      <td>
        <span className={classes.time}>
          {convertTimestampToFormattedDate(blockTimestamp)}
        </span>
      </td>
      <td>
        <span className={classes.time}>
          {convertTimestampToFormattedDate(lastWithdrawnTime)}
        </span>
      </td>{" "}
      <td>
        <div className={classes.shortend}>
          {shortenEthereumAddress(ref1)}
          <CopyToClipboard text={ref1}>
            <FaRegCopy className={classes.copyIcon} />
          </CopyToClipboard>
        </div>
      </td>
      <td>
        {" "}
        <div className={classes.shortend}>
          {shortenEthereumAddress(ref2)}
          <CopyToClipboard text={ref2}>
            <FaRegCopy className={classes.copyIcon} />
          </CopyToClipboard>
        </div>
      </td>
      <td>
        {" "}
        <div className={classes.shortend}>
          {shortenEthereumAddress(ref3)}
          <CopyToClipboard text={ref3}>
            <FaRegCopy className={classes.copyIcon} />
          </CopyToClipboard>
        </div>
      </td>
      <td>
        <div className={classes.shortend}>
          {shortenEthereumAddress(ref4)}
          <CopyToClipboard text={ref4}>
            <FaRegCopy className={classes.copyIcon} />
          </CopyToClipboard>
        </div>
      </td>
      <td>
        <div className={classes.shortend}>
          {shortenEthereumAddress(ref5)}
          <CopyToClipboard text={ref5}>
            <FaRegCopy className={classes.copyIcon} />
          </CopyToClipboard>
        </div>
      </td>
      <td>
        <span className={classes.time}>
          {convertTimestampToFormattedDate(unlockTime)}
        </span>
      </td>
      <td>
        <div className={classes.amount}>
          {formatNumberWithCommas(withdrawnAmount)}
        </div>
      </td>
    </tr>
  );
};

export default SingleRow;
