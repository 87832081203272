import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import { FaRegCopy } from "react-icons/fa6";

import classes from "./DataTable.module.css";
import {
  convertTimestampToFormattedDate,
  shortenEthereumAddress,
} from "../../common/utils";
import { formatEther } from "ethers";

const SingleRow = ({
  amount,
  blockNumber,
  blockTimestamp,
  id,
  stakeID,
  transactionHash,
}) => {
  return (
    <tr>
      <td>#{stakeID}</td>
      <td>
        <div className={classes.amount}>
          {Number(formatEther(amount)).toFixed(2)}
        </div>
      </td>
      <td>
        {" "}
        <div className={classes.shortend}>
          {shortenEthereumAddress(id)}
          <CopyToClipboard text={id}>
            <FaRegCopy className={classes.copyIcon} />
          </CopyToClipboard>
        </div>
      </td>

      <td>
        <div className={classes.shortend}>
          {shortenEthereumAddress(transactionHash)}
          <CopyToClipboard text={transactionHash}>
            <FaRegCopy className={classes.copyIcon} />
          </CopyToClipboard>
        </div>
      </td>
      <td>
        <span className={classes.blockedNumber}>{blockNumber}</span>
      </td>
      <td>
        <span className={classes.time}>
          {convertTimestampToFormattedDate(blockTimestamp)}
        </span>
      </td>
    </tr>
  );
};

export default SingleRow;
