import React from "react";

import classes from "./DataTable.module.css";

import Loader from "../../common/Loader/Loader";

import SingleRow from "./SingleRow";

const DataTable = ({ data, loader }) => {
  return (
    <div className={classes.tableContainer}>
      {loader && <Loader />}
      <table>
        <thead>
          <tr>
            <th>stakeID</th>
            <th>amount</th>
            <th>ID</th>
            <th>user</th>
            <th>transactionHash</th>
            <th>blockNumber</th>
            <th className={classes.txIdContainer}>Time</th>
          </tr>
        </thead>

        <tbody>
          {data.map((el, idx) => {
            return <SingleRow {...el} key={"table-row-" + idx} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
